<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>

      <b-col
        cols="12"
        md="3"
        class="px-1"
      >
        <b-form-group
          label="Trip Type"
          label-for="filterHistoryTripType"
          label-class="font-weight-bolder"
        >
          <b-form-select
            id="filterHistoryTripType"
            v-model="tableHistories.filter.trip_type"
            :options="tableHistories.options.tripTypes"
            :disabled="tableHistories.busy"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- select trip type here --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="5"
        class="px-1"
      >
        <b-form-group
          label="Vehicle"
          label-for="filterHistoryVehicle"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <v-select
            v-model="tableHistories.selected.vehicle"
            placeholder="search vehicle here"
            label="vehicle_name"
            class="vs-white"
            clearable
            append-to-body
            input-id="filterHistoryVehicle"
            :options="(tableHistories.options.vehicles)"
            :loading="(tableHistories.fetching.vehicles)"
            :disabled="(tableHistories.busy || tableHistories.fetching.vehicles)"
            :calculate-position="calculateDropPosition"
          >
            <template #option="{ vehicle_plate, vehicle_name }">
              <div class="d-flex flex-column flex-md-row py-1">
                <div class="flex-grow-1">
                  {{ vehicle_name }}
                </div>
                <strong class="min-w-50px">{{ vehicle_plate }}</strong>
              </div>
            </template>
            <template #no-options="">
              no available vehicle
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
      >
        <b-form-group
          label="Closed From"
          label-for="filterHistoryDateFrom"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterHistoryDateFrom"
            v-model="tableHistories.filter.date_from"
            :max="tableHistories.filter.date_to"
            debounce="1000"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
      >
        <b-form-group
          label="Closed To"
          label-for="filterHistoryDateTo"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterHistoryDateTo"
            v-model="tableHistories.filter.date_to"
            :min="tableHistories.filter.date_from"
            debounce="1000"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filterHistorySearch"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterHistorySearch"
            v-model="tableHistories.filter.search"
            placeholder="search here"
            autocomplete="off"
            debounce="1000"
            type="text"
          />
        </b-form-group>
      </b-col>

    </b-row>
    <!-- Table -->
    <b-row
      v-if="Number(tabIndex) === 1"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableHistories"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :items="tableProvider"
          :busy="tableHistories.busy"
          :filter="tableHistories.filter"
          :fields="tableHistories.fields"
          :stacked="tableHistories.stacked"
          :sort-by.sync="tableHistories.sortBy"
          :sort-desc.sync="tableHistories.sortDesc"
          :sort-direction="tableHistories.sortDirection"
          :filter-included-fields="tableHistories.filterOn"
          :current-page="tableHistories.currentPage"
          :per-page="tableHistories.perPage"
        >
          <template #cell(index)="{ index }">
            {{ tableRowNumber(tableHistories, index) }}
          </template>

          <template #cell(action)="{ item }">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
              <b-button
                size="sm"
                variant="warning"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                :disabled="tableHistories.busy"
                @click.prevent="show(item)"
              >
                View
              </b-button>
            </div>
          </template>

          <template #cell(approvable.purchase_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.transfer_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.status)="{ value }">
            <b
              :class="[
                'min-w-80px',
                statusByVariant(value, 'text')
              ]"
              v-text="value"
            />
          </template>

          <template #cell(status)="{ value }">
            <b
              :class="[
                'min-w-80px',
                statusByVariant(value, 'text')
              ]"
              v-text="value"
            />
          </template>

          <template #cell()="{ value }">
            <div
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableHistories.perPage"
          :options="tableHistories.pageOptions"
          :disabled="tableHistories.busy"
          class="w-100 w-md-25"
          size="sm"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableHistories.currentPage"
          :total-rows="tableHistories.totalRows"
          :per-page="tableHistories.perPage"
          :disabled="tableHistories.busy"
          aria-controls="table"
          prev-text="Prev"
          next-text="Next"
          first-number
          last-number
          pills
        />
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { SLOClosing, SSharedList } from '@/services'

export default {

  name: 'LogisticsOfficerClosingHistory',

  mixins: [
    MISC,
    FORMATTER
  ],

  props: {
    tabIndex: {
      type: Number,
      default: () => 0
    }
  },

  data () {
    return {
      tableHistories: {
        busy: false,
        fetching: {
          vehicles: false
        },
        options: {
          tripTypes: [
            { value: 'all', text: 'All' },
            { value: 'Delivery', text: 'Delivery' },
            { value: 'Pick-Up', text: 'Pick-Up' },
            { value: 'Inter Branch', text: 'Inter Branch' }
          ],
          vehicles: []
        },
        selected: {
          vehicle: null
        },
        filter: {
          search: null,
          date_from: null,
          date_to: null,
          vehicle: null,
          trip_type: 'all'
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 16, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'approvable.created_at', label: 'Requested At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approvable.status', label: 'Request Status', class: 'text-center' },
          { mobile: 3, key: 'approvable.purchase_number', label: 'PO Number', class: 'text-center min-w-150px', sortable: true },
          { mobile: 4, key: 'approvable.transfer_number', label: 'Transfer Number', class: 'text-center min-w-150px', sortable: true },
          { mobile: 5, key: 'approvable.schedule_details.trip_type', label: 'Trip Type', class: 'min-w-150px' },
          { mobile: 6, key: 'approvable.schedule_details.date', label: 'Delivery Date', class: 'min-w-175px', formatter: this.dateShortFormatter, sortable: true },
          { mobile: 7, key: 'approvable.schedule_details.vehicle_plate', label: 'Vehicle Plate', class: 'min-w-150px' },
          { mobile: 8, key: 'approvable.destination_name', label: 'Deliver To', class: 'min-w-200px' },
          { mobile: 9, key: 'approvable.origin_name', label: 'Delivery From', class: 'min-w-200px' },
          { mobile: 10, key: 'approvable.group_name', label: 'Group', class: 'min-w-200px' },
          { mobile: 11, key: 'approvable.transfer_details.length', label: 'Products', class: 'text-center' },
          { mobile: 12, key: 'approvable.delivery_details.customer', label: 'Customer', class: 'min-w-200px' },
          { mobile: 13, key: 'approvable.customer_direct', label: 'D2C', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 14, key: 'status', label: 'Approval Status', class: 'text-center' },
          { mobile: 15, key: 'status_at', label: 'Closed At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {
    'tableHistories.selected.vehicle' (vehicle) {
      this.tableHistories.filter.vehicle = vehicle?.id || null
    }
  },

  mounted () {
    this.getFilterVehicles()
  },

  methods: {

    async getFilterVehicles () {
      this.tableHistories.fetching.vehicles = true
      return await SSharedList.getVehicles({
        include_inactive: 1
      }).then(({ data }) => {
        this.tableHistories.options.vehicles = data
      }).catch(() => {
        this.tableHistories.options.vehicles = []
      }).finally(() => {
        this.tableHistories.fetching.vehicles = false
      })
    },

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableHistories.busy = true
      return await SLOClosing.histories({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_date_from: filter.date_from,
        filter_date_to: filter.date_to,
        filter_trip_type: filter.trip_type,
        filter_vehicle: filter.vehicle
      }).then(({ data }) => {
        this.tableHistories.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableHistories.totalRows = 0
        return []
      }).finally(() => {
        this.tableHistories.busy = false
      })
    },

    tableRefresh () {
      this.$refs.tableHistories.refresh()
    },

    show (stockRequest) {
      this.$emit('show', stockRequest, false)
    }
  }
}
</script>
